var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"8","cols":"12"}},[_c('v-card',{staticClass:"pa-4",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"select_area_form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_vm._v(" Select area to get number of activities in every block of that area ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.bahrain_area,"item-text":"name_en","item-value":"object_id","label":"Select Area","dense":"","outlined":"","error":_vm.error,"rules":_vm.seleted_area_rules},model:{value:(_vm.seleted_area),callback:function ($$v) {_vm.seleted_area=$$v},expression:"seleted_area"}})],1),_c('v-btn',{staticClass:"mt-3",attrs:{"loading":_vm.loading,"color":"teal white--text"},on:{"click":function($event){return _vm.get_data()}}},[_vm._v(" Select ")]),_c('v-col')],1),_c('v-alert',{attrs:{"dense":"","transition":"slide-y-transition","border":"left","color":"blue","type":"info"},model:{value:(_vm.data_alert),callback:function ($$v) {_vm.data_alert=$$v},expression:"data_alert"}},[_vm._v(" No data available for this area. ")])],1)],1)],1)],1)],1),_vm._l((_vm.block_list),function(block,i){return _c('v-row',{key:i},[_c('v-col',{attrs:{"md":"8","cols":"12"}},[(block.activity.length >0)?_c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Block: "+_vm._s(block.block)+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":block.activity,"items-per-page":5},scopedSlots:_vm._u([{key:"item.activity_object_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_activity(item.activity_object_id))+" ")]}},{key:"item.share",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.share)+"% ")]}}],null,true)})],1)],1)],1):_vm._e()]),_c('v-col',{attrs:{"md":"4","cols":"12"}},[(block.activity.length >0)?_c('div'):_vm._e()])],1)}),_c('v-snackbar',{attrs:{"bottom":"","color":"error","outlined":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.error_snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.error_snackbar),callback:function ($$v) {_vm.error_snackbar=$$v},expression:"error_snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_error_message)+" ")]),_c('v-snackbar',{attrs:{"timeout":2000,"bottom":"","color":"success","outlined":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"teal","text":""},on:{"click":function($event){_vm.success_snakbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.success_snakbar),callback:function ($$v) {_vm.success_snakbar=$$v},expression:"success_snakbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_success_message)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }