<template>
	<v-container>
			
		<v-row>
			<v-col>
				<v-card
				outlined
				class="pa-4"
				>
				<v-card-title>
					Growing Business Area since {{get_date(start_date)}}
				</v-card-title>
				<v-card-text>
					<v-data-table
					:loading="loading"
					:headers="headers"
					:items="growing_market"
					:items-per-page="5"
					>
						
					</v-data-table>
				</v-card-text>
				</v-card>

			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card
				outlined
				class="pa-4"
				>
				<v-card-title>
					Shrinking Business Area since {{get_date(start_date)}}
				</v-card-title>
				<v-card-text>
					<v-data-table
					:loading="loading"
					:headers="headers"
					:items="shrinking_market"
					:items-per-page="5"
					>
						
					</v-data-table>
				</v-card-text>
				</v-card>

			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import {get_block_summary} from "@/api/app";
import bahrain_area_block from '@/data/bahrain_area_block.json'
export default {
	name: 'AddedActivityTrend',
	data:function (){
		return {
			loading:false,
			start_date:"",
			end_date:"",
			growing_market:[],
			shrinking_market:[],
			online_data:[],
			bahrain_area:bahrain_area_block,
			headers:[
			{text:"Block",value:"block"},
			{text:"Area",value:"area_name"},
			{text:"Opened",value:"opened_business"},
			{text:"Moved in",value:"moved_in_business"},
			{text:"Closed",value:"closed_business"},
			{text:"Moved out",value:"moved_out_business"},
			{text:"Moved within",value:"moved_within_business"},
			{text:"Change",value:"change"},
			
			],

		}
	},

	methods:{
		get_area_name(block){
			
			for (let i=0;i<bahrain_area_block.length;i++){
				for (let j=0;j<bahrain_area_block[i].block.length;j++){
					if (bahrain_area_block[i].block[j] == block)
						return bahrain_area_block[i].name_en;
				}
			}
			return "None"
		},

		get_date(date){
			if (date ==""){
				return "";
			}

			console.log("date is: ",date)
			const options = { year: "numeric", month: "long", day: "numeric" };

			return new Date(date).toLocaleDateString(undefined,options);
		},
		
		get_data(){
			this.loading=true;
			
			get_block_summary().then((response)=>{
				console.log(response);
				if (response.error == false){
					this.start_date=response.data.start_date
					this.end_date=response.data.end_date
					this.growing_market=response.data.growing_market;
					this.shrinking_market=response.data.shrinking_market;
					this.online_data=response.data.data
					console.log("this.online_data: ",this.online_data);
					for (let i=0;i<this.growing_market.length;i++){
						this.growing_market[i]["area_name"]=this.get_area_name(this.growing_market[i].block)
					}
					for (let i=0;i<this.shrinking_market.length;i++){
						this.shrinking_market[i]["area_name"]=this.get_area_name(this.shrinking_market[i].block)
					}
					
				}else{
					console.log("HTTP error", response.error_message);
				}
				this.loading=false;

			});
		},
		
	},
	created:function(){
		this.get_data();
	},


	// mounted:function(){
	// 	//reset the data		
	// },
	

}
</script>