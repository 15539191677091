<template>
	<v-container>
			<v-row>
				<v-col>
					<h2>Unfortunate Attempt</h2>
				</v-col>
			</v-row>
			<v-alert
				class="mt-4"
				dismissible
				v-model="top_help"
				type="info"
				color="grey"
				border="left"
				>
					Any business closed or change its address in less than two years.
				</v-alert>
			<v-row>
				<v-col>
					<v-card
					outlined
					class="pa-4"
					>
					<opportunity-table
					:loading="loading"
					:opportunity_list="opportunity_list"
					/>
					</v-card>

				</v-col>
			</v-row>
	</v-container>
</template>
<script>
	//issue with pagination cache + page number selection cache
import {load_unfortunate_attempt} from '@/api/app'
import OpportunityTable from '@/components/OpportunityTable.vue'
export default{
	name:'UnfortunateAttempt',
	components:{OpportunityTable},
	data() {
		return {
			top_help:true,
			loading:true,
			opportunity_list:[],
		}
	},
	computed: {
			top_help_store(){return this.$store.getters.top_help}
		},
	watch:{
			top_help:function(val){
				console.log("top help value: ",val);
				this.$store.commit('hide_help',"unfortunate_attempt");
			}
		},
	created () {
		this.top_help=this.top_help_store.unfortunate_attempt;
		this.load_data();
	},
	methods:{
		load_data(){
			this.loading=true;
			load_unfortunate_attempt().then(response =>{
				this.opportunity_list=response.data;
				this.loading=false;
			});
		}
	},

}

</script>