<template>
	<v-container>
		<v-row>
			<v-col md="8" cols="12">
				<v-card
				outlined
				class="pa-4"
				>
				<v-card-text>
					<v-form
						ref="select_area_form"
						lazy-validation
						>
						<v-row>
							<v-col>
							Select area and block to get information about the selected block.
							</v-col>
						</v-row>
						<v-row>

							<v-col cols="4">
								
								<v-autocomplete

									v-model="seleted_area"
									:items="bahrain_area"
									item-text="name_en"
									item-value="object_id"
									label="Select Area"
									dense
									outlined
									:error="error"
									:rules="seleted_area_rules"
									@change="update_block_list()"
									></v-autocomplete>
								
							</v-col>

							<v-col cols="4">
								
								<v-select
									:disabled="disable_block_selection"
									v-model="selected_block"
									:items="selected_area_block_list"
									label="Select Block"
									dense
									outlined
									:error="error"
									:rules="seleted_area_rules"
									@change="enable_get_data_btn()"
									></v-select>
								
							</v-col>

								<v-btn
								:disabled="disable_get_data_btn"
								:loading="loading"
								color="teal white--text" 
								class="mt-3"
								@click="get_data()"
								>
									Select
								</v-btn>
							<v-col>

							</v-col>
						</v-row>
						<v-alert
								dense
								transition="slide-y-transition"
								v-model="data_alert"
								border="left"
								color="blue"
								type="info"
								>
									No data available for this area.
								</v-alert>
					</v-form>
				</v-card-text>
			</v-card>
			</v-col>
		</v-row>



		<v-row v-if="data_loaded">
			<v-col cols="12" md="8">
				<v-card
				:loading="loading"
				outlined
				class="pa-2">
					<v-card-title>
						Block Map
					</v-card-title>
					<div class="map" ref='map' ></div>
				</v-card>
			</v-col>
			<v-col md="4" cols="12">
				
					<v-card 
					:loading="loading"
						outlined
						>
						<v-card-title>
							Block  {{selected_block}} Stat
						</v-card-title>
						<v-card-subtitle>
							Last 6 months
						</v-card-subtitle>
						<v-card-text>
							<p>Opened Business: {{change_address_count.new_branch_count}}</p>
							<v-divider class="my-3"/>
							<p>Closed Business: {{change_address_count.deleted_branch_count}}</p>
							<v-divider class="my-3"/>
							<p>Moved in Business: {{change_address_count.moved_in}}</p>
							<v-divider class="my-3"/>
							<p>Moved out Business:  {{change_address_count.moved_out}}</p>
							<v-divider class="my-3"/>
							<p>Moved within Business:  {{change_address_count.moved_within}}</p>
						</v-card-text>

					</v-card>
				
			</v-col>
		</v-row>

		<v-row v-if="data_loaded" >
			<v-col cols="12" md="6" >
				<v-card
					:loading="loading"
					outlined
					>
					<v-card-title>
						Opened Branches
					</v-card-title>
					<v-card-text>
						<block-details-chart 
						chart_title="Opened Branches"
						:series="new_branches_series"
						:chart_colors="new_chart_colors"></block-details-chart>
					</v-card-text>
					
				</v-card>

			</v-col>

			<v-col cols="12" md="6" >
				<v-card
					:loading="loading"
					outlined
					>
					<v-card-title>
						Closed Branches
					</v-card-title>
					<v-card-text>
						<block-details-chart 
						chart_title="Closed Branches"
						:series="closed_branches_series"
						:chart_colors="closed_chart_colors"></block-details-chart>
					</v-card-text>
					
				</v-card>

			</v-col>

		</v-row>

		<v-row v-if="data_loaded" >
			<v-col cols="12" md="6" >
				<v-card
					:loading="loading"
					outlined
					>
					<v-card-title>
						Moved In
					</v-card-title>
					<v-card-text>
						<block-details-chart 
						chart_title="Moved In"
						:series="moved_in_branches_series"
						:chart_colors="moved_in_chart_colors"></block-details-chart>
					</v-card-text>
					
				</v-card>

			</v-col>

			<v-col cols="12" md="6" >
				<v-card
					:loading="loading"
					outlined
					>
					<v-card-title>
						Moved Out
					</v-card-title>
					<v-card-text>
						<block-details-chart 
						chart_title="Moved Out"
						:series="moved_out_branches_series"
						:chart_colors="moved_out_chart_colors"></block-details-chart>
					</v-card-text>
					
				</v-card>

			</v-col>

		</v-row>

		

		<v-row v-if="data_loaded" >
			<v-col md="12" cols="12">
				
				<v-card
				:loading="loading"
				outlined
				>
				<v-card-title>
					Business Activity in Block: {{selected_block}}
				</v-card-title>
				<v-card-text>
					<v-data-table
					:headers="headers"
					:items="activity_stat"
					:items-per-page="5"
					>
					
					<template v-slot:item.share="{ item }">
						{{item.share}}%
					</template>

					</v-data-table>
						

					
				</v-card-text>

				</v-card>
				
			</v-col>
			
		</v-row>



		<v-snackbar
			v-model="error_snackbar"
			bottom
			color="error"
			outlined
			right>
			{{ snackbar_error_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="pink"
				text

				v-bind="attrs"
				@click="error_snackbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>


		<v-snackbar
			:timeout="2000"
			
			bottom
			color="success"
			outlined
			right
			v-model="success_snakbar">
			{{ snackbar_success_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="teal"
				text

				v-bind="attrs"
				@click="success_snakbar = false"
				>
				Close
			</v-btn>
		</template>
	</v-snackbar>
	</v-container>
</template>
<script>
import {get_block_details} from '@/api/app';
import business_json from '@/data/business_activity_list.json';
import bahrain_area_block from '@/data/bahrain_area_block.json';
import BlockDetailsChart from '@/components/BlockDetails/BlockDetailsChart.vue';
//import MapBlockFence from '@/components/MapBlockFence.vue'
export default {
	name:'AreaActivity',
	components: {BlockDetailsChart},
	//components:{MapBlockFence},
	data:function(){
		return {
			data_loaded:false,
			disable_get_data_btn:true,
			disable_block_selection:true,
			data_alert:false,
			seleted_area_rules:[v => !!v || 'Area is required',],
			error: false,
			loading:false,
			seleted_area:'',
			error_snackbar: false,
			success_snakbar:false,
			snackbar_error_message:"",
			snackbar_success_message:"",
			bahrain_area:bahrain_area_block,
			headers:[
				{ text: 'Activity Name', value: 'name_en',width:440 },
				{ text: 'Activity Name', value: 'name_ar',width:440 },
				{ text: 'Count', value: 'count' ,width:100},
				{ text: 'Percentage', value: 'share',width:120 }],
			block_list:[],
			selected_area_block_list:[],
			selected_block:0,
			block_points:[],
			activity_stat:[],
			change_address_count:{
				moved_in:0,
				moved_out:0,
				moved_within:0,
				new_branch_count:0,
				deleted_branch_count:0,
			},
			new_branches_series:[],
			new_chart_colors:["#01a3a4"],
			closed_chart_colors:["#ee5253"],
			moved_in_chart_colors:["#3498db"],
			moved_out_chart_colors:["#f1c40f"],
			moved_within_chart_colors:["#bdc3c7"],
			new_branch_data_found_alert:false,
			
			closed_branches_series:[],
		
			moved_in_branches_series:[],
		
			moved_out_branches_series:[],
		
			moved_within_branches_series:[],
			
				
		}
	},
	methods:{
		enable_get_data_btn(){
			this.disable_get_data_btn=false;
		},
		update_block_list(){
			// enable block 
			for( let i=0;i<this.bahrain_area.length;i++){
				
				if (this.bahrain_area[i].object_id == this.seleted_area){
					this.selected_area_block_list=this.bahrain_area[i].block;
					this.disable_block_selection=false;
					this.disable_get_data_btn=true;
					break;
				}
			}
			// console.log("selecte area object_id",this.seleted_area);
		},
		get_activity(activity_id){
				for (let i=0;i<business_json.length;i++){
					if (business_json[i].object_id==activity_id){
						return business_json[i].name_en;
					}

				}
				return "Activity name not found!";
			},
		get_name_en_activity(activity_id){
				for (let i=0;i<business_json.length;i++){
					if (business_json[i].object_id==activity_id){
						return business_json[i].name_en;
					}

				}
				return "Activity name not found!";
			},
		get_name_ar_activity(activity_id){
				for (let i=0;i<business_json.length;i++){
					if (business_json[i].object_id==activity_id){
						return business_json[i].name_ar;
					}

				}
				return "Activity name not found!";
			},
	
		get_data(){
			if (this.$refs.select_area_form.validate() ==false){
				return 
			}
			if (this.seleted_area==""){
				this.error_snackbar=true;
				this.snackbar_error_message="Please select an area";
				return 
			}
			this.loading=true
			get_block_details({"block":this.selected_block}).then(response=>{
				console.log("R:",response);
				if (response.error==false){
					this.block_points=response.data.block_points;
					let activity_stat=[]
					for (let i=0;i<response.data.activity_stat.length;i++){
						activity_stat.push({
							count:response.data.activity_stat[i].count,
							share:response.data.activity_stat[i].share,
							name_en:this.get_name_en_activity(response.data.activity_stat[i].activity_object_id),
							name_ar:this.get_name_ar_activity(response.data.activity_stat[i].activity_object_id),
						})
					}
					this.activity_stat=activity_stat;
					this.change_address_count.moved_in=response.data.change_address_stat.moved_in_count;
					this.change_address_count.moved_out=response.data.change_address_stat.moved_out_count;
					this.change_address_count.moved_within=response.data.change_address_stat.moved_within_count;
					this.change_address_count.new_branch_count=response.data.change_address_stat.new_branch_count;
					this.change_address_count.deleted_branch_count=response.data.change_address_stat.deleted_branch_count;
					// let all_zero=false;
					let new_branch_stat=[];
					for(let i=0;i<response.data.change_address_stat.new_branch_graph.length;i++){
						
						new_branch_stat.push({
							"y":response.data.change_address_stat.new_branch_graph[i].count,
							"x":response.data.change_address_stat.new_branch_graph[i].date,
						})
					}
					let closed_branches_series=[]
					for(let i=0;i<response.data.change_address_stat.deleted_branch_graph.length;i++){
						
						closed_branches_series.push({
							"y":response.data.change_address_stat.deleted_branch_graph[i].count,
							"x":response.data.change_address_stat.deleted_branch_graph[i].date,
						})
					}
					let moved_in_branches_series=[]
					for(let i=0;i<response.data.change_address_stat.moved_in_branch_graph.length;i++){
						
						moved_in_branches_series.push({
							"y":response.data.change_address_stat.moved_in_branch_graph[i].count,
							"x":response.data.change_address_stat.moved_in_branch_graph[i].date,
						})
					}
					let moved_out_branches_series=[]
					for(let i=0;i<response.data.change_address_stat.moved_out_branch_graph.length;i++){
						
						moved_out_branches_series.push({
							"y":response.data.change_address_stat.moved_out_branch_graph[i].count,
							"x":response.data.change_address_stat.moved_out_branch_graph[i].date,
						})
					}
					
					this.new_branches_series=[{	name: 'New Branch',	data:new_branch_stat}];
					this.closed_branches_series=[{name: 'Closed Branch',data:closed_branches_series}];
					this.moved_in_branches_series=[{name: 'Moved In Branch',data:moved_in_branches_series}];
					this.moved_out_branches_series=[{name: 'Moved Out Branch',data:moved_out_branches_series}];
					this.fill_data();
					
					
				} else {
					console.log("Error: ", response.error_message);
				}
				this.loading=false;
				
			});
		},
		getMap() {

			let script = document.createElement('script')
			script.src = 'https://www.bing.com/api/maps/mapcontrol?key=AgzeobkGvmpdZTFuGa7_6gkaHH7CXHKsFiTQlBvi55x-QLZLh1rSjhd1Da9bfPhD&callback=getMap';
			document.head.append(script)
			return new Promise(resolve => {
				window.getMap = function() {
					resolve(window.Microsoft)
				}
			})
		},
		async fill_data(){
			this.data_loaded=true;

			let {
				Maps
			} = await this.getMap()

			let exteriorRing=[];
			let maxLat=this.block_points[0][0];
			let minLat=this.block_points[0][0];
			let maxLong=this.block_points[0][1];
			let minLong=this.block_points[0][1];

			for(let i=0;i<this.block_points.length;i++){
				exteriorRing.push(new Maps.Location(this.block_points[i][0],this.block_points[i][1]));
				// calcalute max and min
				if (maxLat > this.block_points[i][0]){maxLat=this.block_points[i][0]}
				if (minLat < this.block_points[i][0]){minLat=this.block_points[i][0]}

				if (maxLong > this.block_points[i][1]){maxLong=this.block_points[i][1]}
				if (minLong < this.block_points[i][1]){minLong=this.block_points[i][1]}
			}
		let box=new Maps.LocationRect.fromEdges(maxLat,maxLong,minLat,minLong);
		var navigationBarMode = Maps.NavigationBarMode;

		let map =new Maps.Map(this.$refs.map, {
			navigationBarMode: navigationBarMode.square,
			bounds:box,
			mapTypeId: Maps.MapTypeId.grayscale,
			supportedMapTypes: [Maps.MapTypeId.road, Maps.MapTypeId.aerial, Maps.MapTypeId.grayscale,Maps.MapTypeId.canvasLight]

		})
		var polyline = new Maps.Polyline(exteriorRing, {
			strokeDashArray: [2, 2],
			strokeColor: 'rgba(22, 160, 133,0.8)',
			strokeThickness: 4
		});
		map.entities.push(polyline);

		},

	}

}

</script>


<style scoped>
.side_titles tr th{
  width: 180px;
}
.table_header_width{
  width: 180px; 
}

.map{
  width:100%;
  height: 480px;
}

.v-menu__content .theme--light .menuable__content__active .v-autocomplete__content{
  z-index: 200 !important;
}

@media only screen and (max-width: 768px) {
  .map{
    height: 450px;
  }
}

</style>
