<template>
	<v-container>
		<v-row>
			<v-col cols="12" md="8">
				
					<v-card
					outlined
					class="pa-4"
					>
					<v-card-title>Select Activity</v-card-title>
					<v-form
						ref="select_activity_form"
						lazy-validation
						>
						<v-row>
							<v-col cols="9" >
								<v-autocomplete
								outlined
								dense
								v-model="selected_activity"
								:items="business_activity_list"
								label="Select Business Activity"
								item-text="name_en"
								item-value="object_id"
								:error="error"
								:rules="selected_activity_rules"	

								></v-autocomplete>
							</v-col>
							<v-col>
								<v-btn
							
								color="teal white--text" 
								@click="get_data"
								:loading="loading"

								>
									Show
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
		
		<v-row>
			<v-col>
				<v-card
				:loading="loading"
				outlined
				class="pa-4"

				>
				<v-card-title>Deleted Activity Time-line</v-card-title>
				<apexchart 
					type="line" 
					height="350" 
					:options="line_chart_options" :series="series"></apexchart>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="4" cols="12">
				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
				<v-card-title>Raw Data</v-card-title>

					<v-data-table
					:headers="headers"
					:items="raw_data"
					:items-per-page="5"
					
					></v-data-table>
				</v-card>

			</v-col>
			<v-col md="8" cols="12">
				<v-card
				outlined
				class="pa-4"
				:loading="loading"
				>
				<v-card-title>Deleted Activity Heatmap</v-card-title>
					<apexchart 
						type="heatmap" 
						height="350" 
						:options="heatmap_chart_options" 
						:series="series"></apexchart>
				</v-card>
			</v-col>

		</v-row>
	</v-container>
</template>
<script>
import {get_deleted_activity_rate} from '@/api/app'
import business_json from '@/data/business_activity_list.json'
export default {
	name:'DeletedActivityRate',
	data:function(){
		return {
			selected_activity_rules:[v => !!v || 'Activity is required',],
			error: false,
			raw_data:[],
			headers:[{ text: 'Date', value: 'date' },{ text: 'Count', value: 'count' }],
			loading:false,
			business_activity_list:business_json,
			selected_activity:'',
			
			online_data:null,
			series: [],
			heatmap_chart_options: {
				chart: {
					height: 250,
					type: 'heatmap',
				},
				dataLabels: {
					enabled: true,
					style: {
						colors: ['#555'],
					},

				},
				colors: ["#ee5253"],
				xaxis: {
					type: 'category',
					categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
					axisTicks: {
					show: true
					},

				},
				
			},
			line_chart_options: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				colors: ['#c8d6e5', '#8395a7','#576574','#222f3e','#ee5253'],
				dataLabels: {
					enabled: true
				},

				stroke: {
					curve: 'smooth'
				},
		
				grid: {
					row: {
					colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5
				},
			},
			xaxis: {
				categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
			}
			},
		}
	},
	methods:{
		get_data:function(){
			if (this.$refs.select_activity_form.validate() ==false){
				return 
			}
			const data_to_send={'object_id':this.selected_activity}
			this.loading=true;
			get_deleted_activity_rate(data_to_send).then((response)=>{

				if (response.error==false){
					this.online_data=response;
					this.series=this.online_data.graph_data;
					this.raw_data=this.online_data.data;

				}else{
					console.log("ERROR :", response.error_message);
				}
				this.loading=false;
				
			})
		}
	}
}
</script>