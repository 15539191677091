<template>
	<v-container>
		<v-row>
			<v-col md="12" cols="12">
				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
				<v-card-title>
					Added Activity Trend from {{start_date}} to {{end_date}}
				</v-card-title>
					<activity-trend-chart 
						chart_title="Added Activity Trend"
						:series="add_activity_series"
						:chart_colors="add_activity_chart_colors">
							
						</activity-trend-chart >
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card
				outlined
				class="pa-4"
				>
					<v-data-table
					:loading="loading"
					:headers="headers"
					:items="add_activity_online_data"
					:items-per-page="5"
					></v-data-table>
				</v-card>

			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-divider />
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12" cols="12">
				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
				<v-card-title>
					Deleted Activity Trend from {{start_date}} to {{end_date}}
				</v-card-title>
					<activity-trend-chart 
						chart_title="Deleted Activity Trend"
						:series="deleted_activity_series"
						:chart_colors="deleted_activity_chart_colors">
							
						</activity-trend-chart >
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card
				outlined
				class="pa-4"
				>
					<v-data-table
					:loading="loading"
					:headers="headers"
					:items="deleted_activity_online_data"
					:items-per-page="5"
					></v-data-table>
				</v-card>

			</v-col>
		</v-row>

	</v-container>
</template>
<script>
import {added_activity_trend,deleted_activity_trend} from "@/api/app";
import ActivityTrendChart from '@/components/ActivityTrendChart.vue';
export default {
	name: 'AddedActivityTrend',
	components: {ActivityTrendChart},
	data:function (){
		return {
			loading:false,
			start_date:"",
			end_date:"",
			add_activity_chart_colors:["#01a3a4"],
			deleted_activity_chart_colors:["#ee5253"],
			add_activity_online_data:[],
			deleted_activity_online_data:[],
			
			headers:[
			{text:"Activity Name",value:"name_en"},
			{text:"Arabic Activity Name",value:"name_ar"},
			{text:"Count",value:"count"},
			],
			add_activity_series:[],
			deleted_activity_series:[],
		}
	},

	methods:{
		
		get_data(){
			this.loading=true;
			let data_to_send={
				'start_date':this.start_date,
				'end_date':this.end_date,
				'market_object_id':this.selected_market_object_id}

			added_activity_trend(data_to_send).then((response)=>{
				console.log(response);
				if (response.error == false){
					this.start_date=response.data.start_date
					this.end_date=response.data.end_date
					let add_activity_series=[];
					this.add_activity_online_data=response.data.data
					console.log("this.online_data: ",this.add_activity_online_data);
					for (let i=0;i<this.add_activity_online_data.length;i++){
						
						add_activity_series.push({
							"y":this.add_activity_online_data[i].count,
							"x":this.add_activity_online_data[i].name_en
						})
					}
					this.add_activity_series=[{data:add_activity_series,"name":"Added Activity"}];
				}else{
					console.log("HTTP error", response.error_message);
				}
				this.loading=false;

			});
			deleted_activity_trend(data_to_send).then((response)=>{
				console.log(response);
				if (response.error == false){
					this.start_date=response.data.start_date
					this.end_date=response.data.end_date
					let deleted_activity_series=[];
					this.deleted_activity_online_data=response.data.data
					console.log("this.online_data: ",this.deleted_activity_online_data);
					for (let i=0;i<this.deleted_activity_online_data.length;i++){
						
						deleted_activity_series.push({
							"y":this.deleted_activity_online_data[i].count,
							"x":this.deleted_activity_online_data[i].name_en
						})
		
					}
					this.deleted_activity_series=[{data:deleted_activity_series,"name":"Deleted Activity"}];
				}else{
					console.log("HTTP error", response.error_message);
				}
				this.loading=false;

			});
		},
		
	},
	created:function(){
		this.get_data();
	},


	mounted:function(){
		//reset the data		
	},
	async activated(){
		if (this.add_activity_online_data.length>0){
			let add_activity_series=[];
			for (let i=0;i<this.add_activity_online_data.length;i++){
				
				add_activity_series.push({
					"y":this.add_activity_online_data[i].count,
					"x":this.add_activity_online_data[i].name_en
				})
			}
			this.add_activity_series=[{data:add_activity_series,"name":"Added Activity"}];
		}
	}

}
</script>