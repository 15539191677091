<template>
	<div class="chart">
		<apexchart 
			ref="chart" 
			type="line" 
			height="350" 
			:options="line_chart_options" 
			:series="series"></apexchart>
	</div>
</template>
<script>
export default {
	name: 'BlockDetailsChart',
	props: ['series','chart_colors','chart_title'],
	data: function ()
		{
		return {

			line_chart_options: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				colors: this.chart_colors,
				dataLabels: {
					enabled: true
				},

				stroke: {
					curve: 'smooth'
				},
		
				grid: {
					row: {
					colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5
				},
			},
			xaxis: {
				categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
			}
			},
				
			
		}
	},

	mounted:function(){
		//this.chartOptions.xaxis.categories=this.categories;
		// console.log("color: ",this.chart_colors," for :",this.chart_title);
	},
	async activated(){
		// console.log("chart ref",this.$refs.chart);
		//fix chart loading in the background 
		this.$refs.chart.refresh();

	}

}
</script>