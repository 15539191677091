<template>
	<v-container>
		<v-row>
			<v-col cols="12" md="8">
				
					<v-card
					outlined
					class="pa-4"
					>
					<v-card-title>Select Activity</v-card-title>
					<v-form
						ref="select_activity_form"
						lazy-validation
						>
						<v-row>
							<v-col cols="9" >
								<v-autocomplete
								outlined
								dense
								v-model="selected_activity"
								:items="business_activity_list"
								label="Select Business Activity"
								item-text="name_en"
								item-value="object_id"
								:error="error"
								:rules="selected_activity_rules"	

								></v-autocomplete>
							</v-col>
							<v-col>
								<v-btn
							
								color="teal white--text" 
								@click="get_data"
								:loading="loading"

								>
									Show
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
		
		<v-row>
			<v-col>
				<v-card
				:loading="loading"
				outlined
				class="pa-4"

				>
				<v-card-title>Added Activity Time-line </v-card-title>
				<activity-rate-chart
				:chart_colors="added_activity_chart_rate_color"
				chart_title="Added Activity Time-line"
				:series="added_activity_series">
				</activity-rate-chart>
				
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="4" cols="12">
				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
				<v-card-title>Added Activity Raw Data</v-card-title>

					<v-data-table
					:headers="headers"
					:items="added_activity_raw_data"
					:items-per-page="5"
					
					></v-data-table>
				</v-card>

			</v-col>
			<v-col md="8" cols="12">
				<v-card
				outlined
				class="pa-4"
				:loading="loading"
				>
				<v-card-title>Added Activity Heatmap</v-card-title>
					<activity-rate-heatmap 
						chart_title="Added Activity Heatmap"
						:chart_colors="added_activity_heatmap_color" 
						:series="added_activity_series"></activity-rate-heatmap>
				</v-card>
			</v-col>

		</v-row>
		<v-row>
			<v-col>
				<v-divider />
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card
				:loading="loading"
				outlined
				class="pa-4"

				>
				<v-card-title>Deleted Activity Time-line </v-card-title>
				<activity-rate-chart
				:chart_colors="deleted_activity_chart_rate_color"
				chart_title="Deleted Activity Time-line"
				:series="deleted_activity_series">
				</activity-rate-chart>
				
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="4" cols="12">
				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
				<v-card-title>Deleted Activity Raw Data</v-card-title>

					<v-data-table
					:headers="headers"
					:items="deleted_activity_raw_data"
					:items-per-page="5"
					
					></v-data-table>
				</v-card>

			</v-col>
			<v-col md="8" cols="12">
				<v-card
				outlined
				class="pa-4"
				:loading="loading"
				>
				<v-card-title>Deleted Activity Heatmap</v-card-title>
					<activity-rate-heatmap 
						chart_title="Deleted Activity Heatmap"
						:chart_colors="deleted_activity_heatmap_color" 
						:series="deleted_activity_series"></activity-rate-heatmap>
				</v-card>
			</v-col>

		</v-row>

	</v-container>
</template>
<script>
import {get_added_activity_rate,get_deleted_activity_rate} from '@/api/app';
import business_json from '@/data/business_activity_list.json';
import ActivityRateChart from '@/components/ActivityRateChart.vue';
import ActivityRateHeatmap from '@/components/ActivityRateHeatmap.vue';
export default {
	name:'AddedActivityRate',
	components: {ActivityRateChart,ActivityRateHeatmap},
	data:function(){
		return {
			selected_activity_rules:[v => !!v || 'Activity is required',],
			error: false,
			// added_activity_chart_rate_color:['#bdc3c7','#9da9b2',
			// 	'#7a8fa0','#5b758b',
			// 	'#42596e','#2c3e50',
			// 	'#01a3a4'], //7 colors
			added_activity_chart_rate_color:['#9da9b2', '#7a8fa0','#5b758b','#42596e','#2c3e50','#01a3a4'],
			deleted_activity_chart_rate_color:['#9da9b2', '#7a8fa0','#5b758b','#42596e','#2c3e50','#ee5253'],
			added_activity_raw_data:[],
			deleted_activity_raw_data:[],
			headers:[{ text: 'Date', value: 'date' },{ text: 'Count', value: 'count' }],
			loading:false,
			business_activity_list:business_json,
			selected_activity:'',
			online_data:null,
			added_activity_series: [],
			deleted_activity_series: [],
			added_activity_heatmap_color:["#22a6b3"],
			deleted_activity_heatmap_color:["#ee5253"],
			
		}
	},
	methods:{
		get_data:function(){
			if (this.$refs.select_activity_form.validate() ==false){
				return 
			}
			const data_to_send={'object_id':this.selected_activity}
			this.loading=true;
			get_added_activity_rate(data_to_send).then((response)=>{

				if (response.error==false){
					this.online_data=response;
					this.added_activity_series=this.online_data.graph_data;
					this.added_activity_raw_data=this.online_data.data;

				}else{
					console.log("ERROR :", response.error_message);
				}
				// this.loading=false;
				
			})
			get_deleted_activity_rate(data_to_send).then((response)=>{

				if (response.error==false){
					this.online_data=response;
					this.deleted_activity_series=this.online_data.graph_data;
					this.deleted_activity_raw_data=this.online_data.data;

				}else{
					console.log("ERROR :", response.error_message);
				}
				this.loading=false;
				
			})
		}
	}
}
</script>