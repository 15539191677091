<template>
	<v-container>
		<v-row>
			<v-col md="8" cols="12">
				<v-card
				outlined
				class="pa-4"
				>
				<v-card-text>
					<v-form
						ref="select_area_form"
						lazy-validation
						>
						<v-row>
							<v-col>
							Select area to get number of activities in every block of that area
							</v-col>
						</v-row>
						<v-row>

							<v-col cols="8">
								
								<v-autocomplete
									v-model="seleted_area"
									:items="bahrain_area"
									item-text="name_en"
									item-value="object_id"
									label="Select Area"
									dense
									outlined
									:error="error"
									:rules="seleted_area_rules"
									></v-autocomplete>
								
							</v-col>
								<v-btn
								:loading="loading"
								color="teal white--text" 
								class="mt-3"
								@click="get_data()"
								>
									Select
								</v-btn>
							<v-col>

							</v-col>
						</v-row>
						<v-alert
								dense
								transition="slide-y-transition"
								v-model="data_alert"
								border="left"
								color="blue"
								type="info"
								>
									No data available for this area.
								</v-alert>
					</v-form>
				</v-card-text>
			</v-card>
			</v-col>
		</v-row>


		
		<v-row v-for="(block,i) in block_list" :key="i">
			<v-col md="8" cols="12">
				<div v-if="block.activity.length >0">
				<v-card
				outlined
				>
				<v-card-title>
					Block: {{block.block}}
				</v-card-title>
				<v-card-text>
						
							<v-data-table

							:headers="headers"
							:items="block.activity"
							:items-per-page="5"
							
							>
							<template v-slot:item.activity_object_id="{ item }">
								{{get_activity(item.activity_object_id)}}
							</template>
							<template v-slot:item.share="{ item }">
								{{item.share}}%
							</template>

							</v-data-table>
						

					
				</v-card-text>

				</v-card>
				</div>
			</v-col>
			<v-col md="4" cols="12">
				<div v-if="block.activity.length >0">
					
				</div>
			</v-col>
		</v-row>
		<v-snackbar
			v-model="error_snackbar"
			bottom
			color="error"
			outlined
			right>
			{{ snackbar_error_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="pink"
				text

				v-bind="attrs"
				@click="error_snackbar = false"
				>
				Close
			</v-btn>
		</template>
		</v-snackbar>


		<v-snackbar
			:timeout="2000"
			
			bottom
			color="success"
			outlined
			right
			v-model="success_snakbar">
			{{ snackbar_success_message }}

		<template v-slot:action="{ attrs }">
			<v-btn
				color="teal"
				text

				v-bind="attrs"
				@click="success_snakbar = false"
				>
				Close
			</v-btn>
		</template>
	</v-snackbar>
	</v-container>
</template>
<script>
import {get_area_activity} from '@/api/app';
import business_json from '@/data/business_activity_list.json';
import bahrain_area_block from '@/data/bahrain_area_block.json';
//import MapBlockFence from '@/components/MapBlockFence.vue'
export default {
	name:'AreaActivity',
	//components:{MapBlockFence},
	data:function(){
		return {
			data_alert:false,
			seleted_area_rules:[v => !!v || 'Area is required',],
			error: false,
			loading:false,
			seleted_area:'',
			error_snackbar: false,
			success_snakbar:false,
			snackbar_error_message:"",
			snackbar_success_message:"",
			bahrain_area:bahrain_area_block,
			headers:[
				{ text: 'Activity Name', value: 'activity_object_id',width:530 },
				{ text: 'Count', value: 'count' },
				{ text: 'Percentage', value: 'share' }],
			block_list:[]
	
		}
	},
	methods:{
		get_activity(activity_id){
				for (let i=0;i<business_json.length;i++){
					if (business_json[i].object_id==activity_id){
						return business_json[i].name_en;
					}

				}
				return "Activity name not found!";
			},
	
		get_data(){
			if (this.$refs.select_area_form.validate() ==false){
				return 
			}
			if (this.seleted_area==""){
				this.error_snackbar=true;
				this.snackbar_error_message="Please select an area";
				return 
			}
			this.loading=true
			get_area_activity({"object_id":this.seleted_area}).then(response=>{
				if (response.error==false){

					this.block_list=response.data;
					let no_data=true;
					for (let b=0;b<this.block_list.length;b++){
						if (this.block_list[b].activity.length >0){
							no_data=false;
						}
					
					}
					if (no_data==true){
						this.data_alert=true;
					}else{
						this.data_alert=false;
					}
					

				} else {
					console.log("Error: ", response.error_message);
				}
				this.loading=false;
				
			});
		},

	}

}

</script>