<template>
<v-container>
	<div v-if="loading_map"  class="text-center">
		<v-row>
			<v-col cols="6">
				<v-progress-linear
					color="primary accent-4"
					indeterminate
					rounded
					height="6"
				></v-progress-linear>
			</v-col>
		</v-row>
	</div>
	<div v-else>
		<div v-if="error">
			<v-row>
				<v-col cols="12" md="6">
					<v-alert border="left"
			color="blue"
			transition="slide-y-transition"
			type="info">
					{{error_message}}
					</v-alert>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-row>
				<v-col cols="12" md="6">
					<v-card
					outlined
					class="pa-2">
						<v-card-title>
							Opportunities map
						</v-card-title>
						<div class="map" ref='map' ></div>
					</v-card>
					
					<v-card
					outlined
					class="pa-2 card-margin">
						<v-card-title>
							Map Key
						</v-card-title>
						<v-card-text>
							<ul class="map_key">
								<li>
									New Opporutinity 
								</li>
								<li>
									Old Opporutnity
								</li>
							</ul>
							<p>
								Note: Click the pin to show opporutnity details.
							</p>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" md="6">
					<div v-if="loading_details"  class="text-center">
						<v-row>
							<v-col >
								<v-progress-linear
									color="primary accent-4"
									indeterminate
									rounded
									height="6"
								></v-progress-linear>
							</v-col>
						</v-row>
					</div>
					<div v-if="opportunity_details !=null">
					<v-card
					outlined
					class="pa-2">
						<v-card-title>
							Opportunity details
						</v-card-title>
						<v-card-text>
							<div>
								Activity(s):
								<br />
								<v-chip class="chip-margin" v-for="activity in opportunity_details.activities" :key="activity.name_en">
								{{activity.name_en}}</v-chip>
							</div>
								<v-simple-table>
									<template v-slot:default>
									<tbody>
										<!-- <tr>
											<td>Activity</td>
											<td>
												<ul class="py-4">
													<li v-for="activity in opportunity_details.activities" :key="activity.name_en">
														<v-chip>{{activity.name_en}}</v-chip>
													</li>
												</ul>         
											</td>
										</tr> -->
										<tr>
											<td>Opporutinity Type</td>
											<td>{{opportunity_details.type}}</td>
										</tr>
										<tr>
											<td>Previous Business</td>
											<td>{{opportunity_details.name_en}}</td>
										</tr>
										<tr>
											<td>Previous Business Age</td>
											<td>{{opportunity_details.branch_age_year}} Year(s)</td>
										</tr>

										<tr>
											<td>Previous Business Registration date</td>
											<td>{{opportunity_details.branch_reg_date}}</td>
										</tr>

										
										<tr>
											<td>Search Distense</td>
											<td>{{opportunity_details.nearby_distance}} Meters</td>
										</tr>
										<tr>
											<td>Number of Business around</td>
											<td>{{opportunity_details.nearby_branch_count}}</td>
										</tr>

									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
						</v-card>

						
						<v-card outlined class="card-margin">
							<v-card-title>
							Nearby Activity within {{opportunity_details.nearby_distance}} Meters
							</v-card-title>
							<v-data-table
							:headers="nearby_activity_header"
							:items="opportunity_details.nearby_activities"
							:items-per-page="5"
							class="elevation-1"
							>
							<template v-slot:item.name_en="{ item }">
								<p style="margin-top:16px">
									{{ item.name_en }}
								</p>
							</template>
							
						</v-data-table>
						</v-card>
						
				
				</div>
				</v-col>
			</v-row>
		</div>
	</div>
</v-container>
</template>
<script>
import {load_opportunity_list} from '@/api/app'
import {load_opportunity_details} from '@/api/app'
export default {	
	name: 'OpportunitiesMap',
	data:function(){
		return {
			error:false,
			error_message:'',
			loading_map:false,
			loading_details:false,
			opportunity_list:[],
			pin_list:[],
			pin_cluster:false,
			opportunity_details:null,
			nearby_activity_header: [
			{ text: 'Activity', value: 'name_en' ,},
			{ text: 'Count', value: 'count' ,width:100,align:'center'},
			],
		}
	},
	methods:{
		fill_data: async function() {
			if (this.opportunity_list.length >0){
				let {
					Maps
				} = await this.getMap()
	//Maps.Location(26.208386, 50.538245)
	var navigationBarMode = Maps.NavigationBarMode;
	let map =new Maps.Map(this.$refs.map, {
		navigationBarMode: navigationBarMode.square,
		center: new Maps.Location(26.201660,50.523222),
		mapTypeId: Maps.MapTypeId.canvasLight,
		supportedMapTypes: [Maps.MapTypeId.road, Maps.MapTypeId.aerial, Maps.MapTypeId.grayscale,Maps.MapTypeId.canvasLight]

	})
	//console.log('map: ',map)
	// //https://www.bing.com/api/maps/sdk/mapcontrol/isdk/adddefaultpushpin#TS
	var infobox = new Maps.Infobox(map.getCenter(), { visible: false });
	for(let i=0;i<this.opportunity_list.length;i++){
		let pin_location=new Maps.Location(this.opportunity_list[i].lat,this.opportunity_list[i].lon);

		//console.log('object_id',this.branches[i].object_id);
		let pushpin = new Maps.Pushpin(pin_location, { color: '#34495e'});
		
		infobox.setMap(map);
		Maps.Events.addHandler(pushpin, 'click', function () {
			infobox.setOptions({ visible: true });
		});
		if (this.opportunity_list[i].new){
			pushpin = new Maps.Pushpin(pin_location, { color: '#16a085'});
		}
		
		let info_box_description=""
		
		info_box_description=this.opportunity_list[i].activities[0].name_en
		if (this.opportunity_list[i].activities.length >1){
			for (let a =1;a<this.opportunity_list[i].activities.length;a++){
				info_box_description=info_box_description+"<br />"+this.opportunity_list[i].activities[a].name_en
			}	
		}
		
		pushpin.metadata = {
				description: info_box_description
			};
		Maps.loadModule(['Microsoft.Maps.SpatialMath', 'Microsoft.Maps.Contour'], function () {
			let locs = Maps.SpatialMath.getRegularPolygon(pin_location, 250, 36, Maps.SpatialMath.DistanceUnits.Meters);

			let circle1=new Maps.ContourLine(locs, 'rgba(0,150,200,0.5)');
			var layer = new Maps.ContourLayer([circle1], {
				colorCallback: function (val) {
					//console.log("Color clicked",val);
					return val;
					},
				polygonOptions: {
					strokeThickness: 0
				}
				});
				map.layers.insert(layer);
			});
		//pushpin.setId(this.branches[i].object_id);
		pushpin.objectId=this.opportunity_list[i].object_id;
		// let branch=this.branches[i];
		let _this=this;
		// this.selected_branches=[];
		Maps.Events.addHandler(pushpin, 'click', function () { 
			//map.setView({ center: args.target.getLocation(), zoom: 15 }); 
			// _this.selected_opportunity=[];
			// _this.selected_branches.push(branch);
			console.log("pin object id: ",pushpin.objectId);
			console.log('single push pin',pushpin);
			_this.get_opportunity_details(pushpin.objectId);
			infobox.setOptions({
				location: pushpin.getLocation(),
				title: "Activities",
				description: pushpin.metadata.description,
				visible: true
			});
		});

		this.pin_list.push(pushpin);
		if (this.pin_cluster==false){
			map.entities.push(pushpin);
		}
	}
	
	}
  },

		get_data:function(){
			this.loading_map=true;

			this.opportunity_list=[];
			load_opportunity_list().then((response)=>{

			if (response.error ==false){
					this.opportunity_list=response.data;
					this.error=false;
					this.fill_data();
				}
				else {
				// show error 
					this.error=true;
					this.error_message=response.error_message;
			}
			this.loading_map=false;
			})
	},
		getMap() {
			let script = document.createElement('script')
			script.src = 'https://www.bing.com/api/maps/mapcontrol?key=AgzeobkGvmpdZTFuGa7_6gkaHH7CXHKsFiTQlBvi55x-QLZLh1rSjhd1Da9bfPhD&callback=getMap';
			document.head.append(script)
			return new Promise(resolve => {
				window.getMap = function() {
					resolve(window.Microsoft)
				}
			})
		},
		get_opportunity_details(object_id){
			this.loading_details=true;
			this.opportunity_details=null;
			load_opportunity_details({object_id}).then((response)=>{
			
			let online_data=response.data;
			//this.chart_data=result_data;
			console.log('online data',online_data);
			if (response.error ==false){
				this.opportunity_details=online_data;
				this.fill_opportunity_details()
				this.loading_details=false;
			}
			else{
				// show error 
				this.error=true;
				this.error_message=response.data.error_message;
			}
			this.loading_details=false;
			})
		},
		fill_opportunity_details(){
			if(this.opportunity_details !=null){
				console.log("old branch name: ", this.opportunity_details.name_en);
			}
		}
	},
	async mounted () {
		this.get_data();
	},
}

</script>

<style scoped>
.card-margin{
	margin-top: 16px;
}
.chip-margin{
	margin-top: 8px;
}
.side_titles tr th{
  width: 180px;
}
.table_header_width{
  width: 180px; 
}

.map{
  width:100%;
  height: 585px;
}

.v-menu__content .theme--light .menuable__content__active .v-autocomplete__content{
  z-index: 200 !important;
}

@media only screen and (max-width: 768px) {
  .map{
	height: 450px;
  }
}

    .map_key li {
        list-style: none;
    
    }
    .map_key li:before {
        content:"·";
        font-size:120px;
        vertical-align:middle;
        line-height:20px;
        
    }
    .map_key li:first-child:before {
        color: #16a085;
    }
    .map_key li:nth-child(2):before {
        color: #34495e;
    }
    
    
 
</style>