<template>
	<v-container>
				<v-row>
			<v-col md="12" cols="12">
				<v-card
				:loading="loading"
				outlined
				class="pa-4"
				>
				<v-card-title>
					Deleted Activity Trend from {{start_date}} to {{end_date}}
				</v-card-title>

					<apexchart 
						type="bar" 
						height="450" 
						:options="chartOptions" 
						:series="series"></apexchart>

				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card
				outlined
				class="pa-4"
				>
					<v-data-table
					:loading="loading"
					:headers="headers"
					:items="online_data"
					:items-per-page="5"
					></v-data-table>
				</v-card>

			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import {deleted_activity_trend} from "@/api/app";
export default {
	name: 'DeletedActivityTrend',
	data:function (){
		return {
			loading:false,
			start_date:"",
			end_date:"",
			
			online_data:[],
			
			headers:[
			{text:"Activity Name",value:"name_en"},
			{text:"Arabic Activity Name",value:"name_ar"},
			{text:"Count",value:"count"},
			],
			series:[],
			chartOptions:{
				colors:["#ee5253"],
				chart: {
					type: 'bar',
					height: 450
				},
				plotOptions: {
					bar: {
						horizontal: false,
						
						endingShape: 'rounded'
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},

				xaxis: {
					type:'category',
					categories: [],

				},
				yaxis: {
					
				},
				fill: {
					opacity: 1
				},
				
			},

		}
	},

	methods:{
		
		get_data(){
			this.loading=true;
			let data_to_send={
				'start_date':this.start_date,
				'end_date':this.end_date,
				'market_object_id':this.selected_market_object_id}

			deleted_activity_trend(data_to_send).then((response)=>{
				console.log(response);
				if (response.error == false){
					this.start_date=response.data.start_date
					this.end_date=response.data.end_date
					let series=[];
					this.online_data=response.data.data
					console.log("this.online_data: ",this.online_data);
					for (let i=0;i<this.online_data.length;i++){
						
						series.push({
							"y":this.online_data[i].count,
							"x":this.online_data[i].name_en
						})
		
					}
					this.series=[{data:series,"name":"Deleted Activity"}];
				}else{
					console.log("HTTP error", response.error_message);
				}
				this.loading=false;

			});
		},
		
	},


	mounted:function(){
		this.get_data();
	},

	async activated(){
		if (this.online_data.length>0){
			let series=[];
			for (let i=0;i<this.online_data.length;i++){
				
				series.push({
					"y":this.online_data[i].count,
					"x":this.online_data[i].name_en
				})
			}
			this.series=[{data:series,"name":"Deleted Activity"}];
		}
	}

}
</script>