<template>
	<apexchart 
		type="heatmap" 
		height="350" 
		:options="heatmap_chart_options" 
		:series="series"></apexchart>
</template>
<script>
export default {
	name: 'ActivityRateHeatmap',
	props: ['series','chart_colors','chart_title'],
	data: function(){
		return {
			heatmap_chart_options: {
				chart: {
					height: 250,
					type: 'heatmap',
				},
				dataLabels: {
					enabled: true,
					style: {
						colors: ['#555'],
					},
				},
				colors: this.chart_colors,
				xaxis: {
					type: 'category',
					categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
					axisTicks: {
					show: true
					},

				}

			}
		}
	}
}
</script>