<template>
	<div class="chart">
		<apexchart 
			type="line" 
			height="350" 
			:options="line_chart_options" :series="series"></apexchart>
		
	</div>
</template>
<script>
export default {
	name: 'ActivityRateChart',
	props: ['series','chart_colors','chart_title'],
	data: function(){
		return {
			line_chart_options: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				colors: this.chart_colors,
				dataLabels: {
					enabled: true
				},

				stroke: {
					curve: 'smooth'
				},
		
				grid: {
					row: {
					colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5
				},
			},
			xaxis: {
				categories: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
			}
			},
		}
		}
	}

</script>