<template>
	<div class="chart">
		<apexchart ref="chart" type="bar" height="450" :options="chartOptions" :series="series"></apexchart>
	</div>
</template>
<script>
export default {
	name: 'DashboardChart',
	props: ['series','chart_colors','chart_title'],
	data: function ()
		{
		return {
			
			chartOptions: {
				// noData:{
				// 	text:"No Data",
				// 	align: 'center',
				// 	verticalAlign: 'middle',
				// },
				// title:{
				// 	text: "Chart title",
				// },
				
				colors:this.chart_colors,
				chart: {
					type: 'bar',
					height: 350,
					toolbar: {
						show:true,
						export: {
							svg: {
								filename:this.chart_title,
							},
							png:{
								filename:this.chart_title,
							},
							csv:{
								filename:this.chart_title,
							},
						}

					}
				},
				plotOptions: {
					bar: {
						horizontal: false,
						
						endingShape: 'rounded'
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},

				xaxis: {
					type:'category',
					categories: [],
				},
				yaxis: {
					
				},
				fill: {
					opacity: 1
				},
				
			},
		}
	},

	mounted:function(){
		//this.chartOptions.xaxis.categories=this.categories;
	},
	async activated(){
		// console.log("chart ref",this.$refs.chart);
		//fix chart loading in the background 
		this.$refs.chart.refresh();

	}

}
</script>